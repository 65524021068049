<template>
    <div>
        <v-row>
            <v-col cols="12" sm="6">
                <v-list>
                    <v-subheader style="font-size: 18px">Assigned Roles</v-subheader>
                    <v-divider></v-divider>
                    <v-card flat v-for="(role, i) in roles" :key="i">
                        <v-card-title>
                            <v-icon class="mr-1">{{ role.icon }}</v-icon> {{ role.name }}
                        </v-card-title>
                        <v-card-text>
                            No assigned parties.
                        </v-card-text>
                    </v-card>
                </v-list>
            </v-col>

            <v-col cols="12" sm="6">
                <v-list>
                    <v-subheader style="font-size: 18px">Available Add-ons</v-subheader>
                    <v-divider></v-divider>
                    <v-card v-for="(service, i) in services" :key="i"
                        style="background-color: var(--v-component-base) !important; border-radius: 20px" :class="'my-2'">
                        <v-card-title>
                            <v-icon class="mr-1">{{ service.icon }}</v-icon> {{ service.name }}
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-list>
                                        <v-list-item v-for="(feature, k) in service.features" :key="k">
                                            <v-list-item-action>
                                                -
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-wrap">{{ feature }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-container fill-height>
                                        <v-row justify="center" align="center">
                                            <v-btn v-if="!selectedServices.includes(service.value)" @click="selectedServices.push(service.value)" color="blue" outlined style="text-transform: none"
                                                rounded><v-icon>add</v-icon> Subscribe</v-btn>
                                                <v-btn v-else-if="selectedServices.includes(service.value)" @click="selectedServices.splice(selectedServices.indexOf(service.value),1)" color="success" outlined style="text-transform: none"
                                                rounded><v-icon>check</v-icon> Subscribed</v-btn>
                                        </v-row>
                                    </v-container>
                                </v-col>

                            </v-row>

                        </v-card-text>
                    </v-card>

                </v-list>
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    data: () => ({
        roles: [
            { name: 'Transporter', value: 'transporter', icon: 'local_shipping' },
            { name: 'Documentation', value: 'documentation', icon: 'description' },
            { name: 'Temperature Monitoring', value: 'thermostat', icon: 'thermostat' },
            { name: 'Port Monitoring', value: 'thermostat', icon: 'assessment' }
        ],
        selectedServices: [],
        services: [
            {
                name: 'Container Tracking', value: 'container', icon: 'gps_not_fixed',

                features: ['120+ Carriers', 'Live data']
            },
            {
                name: 'Vessel Tracking', value: 'vessel', icon: 'directions_boat',

                features: ['AIS tracking', 'Satellite tracking']
            },
            {
                name: 'Temperature Monitoring', value: 'temperature', icon: 'thermostat',

                features: ['Supports multiple devices including SensiTech, DeltaTrak & Tive']
            },
        ]
    })
}
</script>
<style>
.tile1 {
    background-image: linear-gradient(to right top, #272727, #29292b, #292c2f, #283032, #283334, #273a3a, #26413e, #264840, #225548, #1b6250, #116f56, #007d5c);
}

.tile2 {
    background-image: linear-gradient(to right top, #272727, #29292a, #2a2b2d, #2a2d30, #2a2f33, #2b353c, #2b3b46, #2b414f, #2c4c62, #2d5876, #2f638a, #336e9f);
}

.tile3 {
    background-image: linear-gradient(to right top, #272727, #272627, #282525, #282423, #272320, #302a23, #383326, #3d3c29, #535031, #6a653a, #837a42, #9d904a);
}</style>